// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type { FactorOption } from '@imprivata-cloud/authn';

export enum ImprHeaders {
  ImprClientName = 'Impr-Client-Name',
  ImprCodingCtx = 'Impr-Coding-Ctx',
  ImprInAppSession = 'Impr-In-App-Session',
  ImprSessionId = 'Impr-Session-Id',
  ImprTenantId = 'Impr-Tenant-Id',
  ImprAuthorization = 'Impr-Authorization',
  ImprWorkflowId = 'Impr-Workflow-Id',
  StrictTransportSecurity = 'Strict-Transport-Security',
  XContentTypeOptions = 'X-Content-Type-Options',
  XFrameOptions = 'X-Frame-Options',
  XSSProtection = 'X-XSS-Protection',
}

export type IdpAuthnStartRequest = {
  spRequestId: string;
  availableFactorTypes?: string[];
};

export type IdpAuthnStartResponse = {
  authnData?: AuthnData;
  idpResponseData?: IdpResponseData;
};

export type AuthnData = {
  nextAction: string; // NextAction;
  startAuthnData?: StartAuthnSessionData;
};

export type StartAuthnSessionData = {
  userId: string;
  username: string;
  nextFactors: FactorOption[];
  oidcRequestData: OidcRequestData;
};

export type OidcRequestData = {
  codeChallenge: string;
  codeChallengeMethod: string;
};

export type IdpResponseData = {
  aadAuthnUrl: string;
  aadAuthnAttributes: Record<string, string>;
};

export type IdpAuthnContinueWithIdTokenRequest = {
  spRequestId: string;
  availableFactorTypes: string[];
};

export type IdpAuthnContinueWithIdTokenResponse = {
  authnData?: AuthnData;
  idpResponseData?: IdpResponseData;
};

export type IdpAuthnContinueWithAuthzCodeRequest = {
  spRequestId: string;
  oidcAuthzCode: string;
};

export type HandleAuthnArtifactRequest = {
  rpRequestId: string;
  authorizationCode: string;
};

// NOTE: not defined yet
export type HandleAuthnArtifactResponse = {
  redirectUri: string;
  authorizationCode: string;
  // (from IDL)
  // RP generated opaque value used to maintain its state between the request and the callback.
  // NOTE: this value is present if the Authorization Request included the state parameter.
  state?: string;
};

export type IdpAuthnContinueWithAuthzCodeResponse = {
  idpResponseData: IdpResponseData;
};

export enum NextAction {
  GET_IDENTITY_TOKEN = 'get-id-token',
  START_ASTRA_AUTHN = 'start-astra-authn',
}
