// Copyright 2024, Imprivata, Inc.  All rights reserved.

export const apiReadyEvent = 'on_impr_custom_api_script_ready';
export const extensionCookieFlagName = 'eamc_extension_installed';

export const isExtensionCookieFlagSet = () => {
  return document.cookie.includes(`${extensionCookieFlagName}=1`);
};

export const isApiReady = (): boolean => {
  // eslint-disable-next-line
  // @ts-ignore
  return !!window?.impr?.authn?.getIdentityToken;
};

export const getIdToken = async (): Promise<string | undefined> => {
  if (isApiReady()) {
    // eslint-disable-next-line
    // @ts-ignore
    const res = await window.impr.authn.getIdentityToken();
    console.log('[DEBUG] got response from getIdentityToken');
    return res.id_token;
  }
  console.log('[DEBUG] impr.authn.getIdentityToken is not available');
};

export const waitForExtensionApi = (callback: () => void): void => {
  if (!isExtensionCookieFlagSet()) {
    console.log(
      '[DEBUG] starting, extension is not installed (cookie flag is not set)',
    );
    callback();
    return;
  }

  if (isApiReady()) {
    console.log('[DEBUG] starting, extension API is ready');
    callback();
  } else {
    const listener = () => {
      document.removeEventListener(apiReadyEvent, listener);
      clearTimeout(timeoutId);
      console.log('[DEBUG] starting, extension event was fired');
      callback();
    };

    console.log(
      '[DEBUG] extension is installed, waiting for API ready event...',
    );
    document.addEventListener(apiReadyEvent, listener);

    const timeoutId = setTimeout(() => {
      console.log(
        '[DEBUG] starting, extension is installed, but API is not ready, timeout exceeded',
      );
      document.removeEventListener(apiReadyEvent, listener);
      callback();
    }, 10 * 1000);
  }
};
